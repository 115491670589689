export const STORE = '/store';
export const STORE_LIST = STORE + '/list';
export const STORE_CREATE = STORE + '/create';
export const STORE_EDIT = STORE + '/edit';
export const STORE_CONFIG = STORE + '/config';
export const STORE_CONFIG_DASHBOARD = STORE_CONFIG + '/dashboard';
export const STORE_CONFIG_DASHBOARD_PATH = 'dashboard';
export const STORE_CONFIG_GENERAL = STORE_CONFIG + '/general';
export const STORE_CONFIG_GENERAL_PATH = 'general';

export const STORE_CONFIG_AUTO_DEPLOY = STORE_CONFIG + '/auto-deploy';
export const STORE_CONFIG_AUTO_DEPLOY_PATH = 'auto-deploy';
export const CATALOG = '/catalog';
export const CATALOG_EDIT = CATALOG + '/edit/:id';
export const CATALOG_DEPLOY = CATALOG + '/catalog-deploy';
export const CATALOG_DEPLOY_SKU_NAME = CATALOG + '/catalog-deploy-sku-name';
export const CATALOG_UPDATE_FORCE = CATALOG + '/catalog-update-force';
export const CATALOG_UPDATE_PARTIAL = CATALOG + '/catalog-update-partial';
export const CATALOG_UPDATE_IMAGE = CATALOG + '/catalog-update-image';
export const CATALOG_UPDATE_STATUS = CATALOG + '/catalog-update-status';
export const CATALOG_UPDATE_DELETE = CATALOG + '/catalog-update-delete';
export const CATALOG_UPDATE_ATTRIBUTE = CATALOG + '/catalog-update-attribute';
export const CATALOG_AUTO_UPDATE_SKU_NAME = CATALOG + '/catalog-auto-update-sku-name';
export const CATALOG_AUTO_DELETE = CATALOG + '/catalog-auto-delete';
export const CATALOG_AUTO_DEPLOY_CREATE_SITE = CATALOG + '/catalog-auto-deploy-create-site';
export const STORE_CONFIG_PRODUCT_ON_SITE = STORE_CONFIG + '/product-on-site';
export const STORE_CONFIG_PRODUCT_ON_SITE_PATH = 'product-on-site';
export const STORE_CONFIG_PRODUCT_FEEDS_PATH = 'product-feeds';
export const STORE_CONFIG_PAGES = STORE_CONFIG + '/pages';
export const STORE_CONFIG_PAGES_PATH = 'pages';
export const CARRIER = '/carrier';
export const REPORT = '/reports';
export const REPORT_REQUEST_CATALOG = REPORT + '/report-request-catalog';
export const REPORT_REQUEST_CATALOG_DESIGN = REPORT + '/report-request-catalog-design';
export const REPORT_SIMILAR_CATALOG = REPORT + '/report-similar-catalog';
export const CARRIER_LIST = CARRIER + '/list';
export const CATALOG_LIST = CATALOG + '/catalog-list';
export const CATALOG_PRICE = CATALOG + '/price';
export const PRICING_TABLE = CATALOG + '/pricing-table';
export const CATALOG_CONTENT = CATALOG + '/catalog-content';
export const SITE_MAPPING = CATALOG + '/site-mapping';
export const META_TITLE = CATALOG + '/meta-title';
export const VENDOR_TITLE = CATALOG + '/vendor-title';
export const REQUEST_HISTORY = CATALOG + '/request-history';
export const CATALOG_COLOR = CATALOG + '/catalog-color';
export const LABELING = '/labeling';
export const PREDICT_CATEGORIES = LABELING + '/actual-categories';
export const CUSTOM_LABELS = LABELING + '/custom-labels';
export const REPLACE_CHARACTERS = LABELING + '/replace-characters';
export const PAGES_TEMPLATES = LABELING + '/configuration-templates';
export const GROUP_COMPILE_KEYWORDS_PATH = 'group-compile-keywords';
export const GROUP_COMPILE_KEYWORDS = LABELING + '/' + GROUP_COMPILE_KEYWORDS_PATH;
export const COMPILE_KEYWORDS_PATH = 'compile-keywords';
export const COMPILE_KEYWORDS = LABELING + '/' + COMPILE_KEYWORDS_PATH;
export const CATALOG_AUTO_DEPLOY = CATALOG + '/catalog-auto-deploy';
export const CATALOG_AUTO_UPDATE_FORCE = CATALOG + '/catalog-auto-update-force';
export const STORE_CONFIG_CONFIG = STORE_CONFIG + '/config';
export const STORE_CONFIG_CONFIG_PATH = 'config';
export const CATALOG_UPDATE_AMZ = CATALOG + '/catalog-update-amz';
export const DESIGN_LIST = '/designs/list';
export const DESIGN_TEMPLATES = 'templates/list';
export const KEYWORDS = 'keywords/list';
export const DESIGN_NICHE = LABELING + '/design-niche';
